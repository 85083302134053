<script>
import { issue } from '@/api/beijing28'
import { caizhong } from "@/api/home";

export default {
    name: 'GameList',
    components:{
        Header: () => import('components/base/Header'),
    },
    data() {
        return {
            games:null,
            header:{
                mainTitle: getWord('auctioneer_by_ui'),
            },
            countdownTimer:null,
            gameCodenames:null,
            currentCount:0,
            retriveCurrentDrawingRemainFrequency:200,
            currentTitle:null,
            groupedGames:null,

            imgUrlTitle: '//cpwap.'+process.env.VUE_APP_PROD_DOMAIN+'/',
            cloudBaseFolder:this._TEMPLATE,
        }
    },
    props: [
        
    ],
    inject:[
        
    ],
    methods: {
        swtich(type){
            if (type==='all') {
                this.currentTitle='all'
                this.games.forEach(item=>{
                    item.show=true;                
                })
            }
            if (type==='high') {
                this.currentTitle='high'
                this.games.forEach(item=>{
                    item.show=true;                
                })
                this.games.filter(item=>{
                    if (item.frequency==="Low") {
                        return true;
                    }                
                }).forEach(item=>{
                    item.show=false;
                })
            }
            if (type==='low') {
                this.currentTitle='low'
                this.games.forEach(item=>{
                    item.show=true;                
                })
                this.games.filter(item=>{
                    if (item.frequency==="High") {
                        return true;
                    }                
                }).forEach(item=>{
                    item.show=false;
                })
            }
        },
        routerLink(item) {            
            if (item.codename==='xyft' || item.codename==='bjsc' || item.codename==='cqssc' || (item.codename.indexOf('28')>-1 && item.codename.indexOf!=='pc28one') ) {
                this.$router.push({
                    path:'beijing28/room_list',
                    query: {
                        type: item.codename,
                        name: item.name
                    }
                })
            }else {
                this.$router.push({
                    path:'/t',
                    query: {
                        type: item.codename,
                        name: item.name
                    }
                })                    
            }            
        },
        retriveCurrentDrawingRemain(gameType,_item) {
            var currentItem=null
            if (gameType instanceof Array) {
                currentItem=gameType[this.currentCount]
            }else {
                currentItem=gameType
            }
            if (this.currentCount===gameType.length) return false;
            var that=this;

            var currentObject=null;

            if (_item) {
                currentObject=this.games.filter(item=>{
                    return item.codename===currentItem
                })[0]
            }else {
                currentObject=this.games.filter( (item,index)=>{
                    return this.currentCount===index
                })[0]
            }

            return new Promise(function (resolve,reject) {
                // issue(currentItem).then(result => {
                    // if (result.data.code === 'SUCCESS' && result.data.result.status===1) {
                    if (that.$store.state.currentDrawing && that.$store.state.currentDrawing[currentItem]) {
                        var result = that.$store.state.currentDrawing[currentItem].time_diff;
                        resolve(result)
                    }else {
                        resolve(null)
                    }
                // })
            })
            .then(function (result) {
                if (result!==null) {
                    var item=currentObject;
                        item.countdown=result;
                        item.timer=setInterval(function(){
                            if (item.countdown===0) {
                                if (item.timer!==null) {
                                    window.clearInterval(item.timer)
                                }
                                setTimeout(function(){
                                    that.retriveCurrentDrawingRemain(item.codename,item)
                                },6000)
                            }else {
                                // Object.assign(item.lastDrawingResult,that.$store.state.lastDrawing && that.$store.state.lastDrawing[currentItem])
                                --item.countdown
                            }
                        },1000)
                }
                if (gameType instanceof Array) {
                    that.currentCount++;

                    that.countdownTimer=setTimeout(function(){
                        that.retriveCurrentDrawingRemain(gameType)
                    },that.retriveCurrentDrawingRemainFrequency)
                }else {
                    return false;
                }
            
            });
        },
        formatCountdownTimer(timeStamp){
            // debugger
            if (isNaN(timeStamp)) {
                return 'ERROR: invalid countdown format'
            }else {
                var hours=parseInt(timeStamp/3600)
                var minutes=parseInt(timeStamp%3600/60)
                var seconds=parseInt(timeStamp%3600%60)
                return (hours>9?hours:'0'+hours)+':'+(minutes>9?minutes:'0'+minutes)+':'+(seconds>9?seconds:'0'+seconds)
            }
        },
        fetchGameIconPath(item) {
            return item.img
        },
    },
    beforeRouteLeave(to, from, next){
        this.$root.$emit('closeBottomTab', true)
        next();
    },
    mounted() {
        var that=this;
        this.$root.$emit('closeBottomTab', false)

        function groupBy(objectArray, property) {
          return objectArray.reduce(function (acc, obj) {
            var key = obj[property];
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
          }, {});
        }

        caizhong().then(result => {
            if (result.data.code == "SUCCESS") {
                this.currentTitle='all'
                var data=result.data.result.numbers.children;

                var games=[];
                Object.keys(data).forEach(item=>{
                    data[item].children.forEach(item=>{
                        games.push(item)
                    })
                })

                this.groupedGames=groupBy(games,'frequency')

                this.games=[];

                games.forEach( (item,index)=>{
                    this.$set( this.games, index, Object.assign({},item,{
                        codename:item.type,
                        countdown:null,
                        timer:null,
                        show:true,
                        img: this.imgUrlTitle + this.cloudBaseFolder + '/home/game-list/' + item.type + '.png',
                    }));
                })

                

                //data format done
                this.CustomScroll(this.$refs.inner.id);

                var codenameList=[];
                (this.games.reduce((acc, value) => acc.concat(value), [])).forEach(item=>{
                    
                        codenameList.push(item.codename)
                    
                })
                this.gameCodenames=[...new Set(codenameList)];

                function run(){
                    if (that.$store.state.currentDrawing && that.$store.state.lastDrawing) {
                        that.retriveCurrentDrawingRemain(that.gameCodenames);    
                    }else {
                        setTimeout(function(){
                            run();
                        },1000)
                    }    
                }  

                run(); 
            }else {
                app.Mint.Toast(result.data.msg)
            }
        });
    },
    computed:{
        currentDrawing(){
            return this.$store.state.currentDrawing && this.$store.state.currentDrawing[this.gameType]
        },
        lastDrawing(){
            return this.$store.state.lastDrawing && this.$store.state.lastDrawing[this.gameType]
        },
    },
    watch:{
        
    },
    beforeDestroy(){
        (this.games.reduce((acc, value) => acc.concat(value), [])).forEach(item=>{
            // item.forEach(item=>{
                if (item.timer!==null) {
                    window.clearInterval(item.timer)
                    item.timer=null;
                }
            // })
        })
        if (this.countdownTimer!==null) {
            window.clearTimeout(this.countdownTimer)
        }
    }
};
</script>
<template>    
    <section id="game-list" ref="gameList" :class="_TEMPLATE">
        <Header
            :mainTitle="header.mainTitle"
            :backHome=true
            :backButton=true
        />
        <div class="inner" id="inner" ref="inner">
            <ul class="title">
                <li @click='swtich("all")' :class="{active:this.currentTitle==='all'}">
                    <i class="iconfont icon-gouwuche"></i>
                    {{ getWord('all_games3') }}
                </li>
                <li @click='swtich("high")' :class="{active:this.currentTitle==='high'}">
                    <i class="iconfont icon-alarmclock"></i>
                    {{ getWord('gaopincai') }}
                </li>
                <li @click='swtich("low")' :class="{active:this.currentTitle==='low'}">
                    <i class="iconfont icon-Clock"></i>
                    {{ getWord('dipincai') }}
                </li>
            </ul>
            <ul class="block">
                <li v-for="item in games" v-if="item.show" @click="routerLink(item)">
                    <img :src="fetchGameIconPath(item)" />
                    <b>{{item.name}}</b>
                    <span :class="{number:item.countdown}">{{item.countdown?formatCountdownTimer(item.countdown):getWord('wait_for_handicap_renew')}}</span>
                </li>
            </ul>
        </div>
    </section>
</template>
<style lang='scss' scoped type="text/css">
section#game-list {
    background-color: #F5F5F9;
    overflow:auto;
    display: flex;
    flex-direction: column;
    height: 100%;

    .inner {
        overflow: auto;
        background-color: #F2F2F2;

        ul {

            &.title {
                display: flex;                    

                li {
                    display: flex;
                    text-align: center;
                    width: 33.33%;
                    list-style: none;
                    justify-content: center;
                    background-color: #171719;
                    color: #ffffff;
                    flex-direction: column;
                    font-size: .3rem;
                    padding: .15rem 0;

                    &.active {
                        background-color: #CC1C1C;
                    }

                    .iconfont {
                        font-size: .5rem;
                    }
                }
            }

            &.block {
                background-color: #ffffff;
                min-height: 2rem;
                display: flex;
                margin-bottom: .1rem;
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                li {
                    width: 33.33%;
                    padding: .3rem 0;
                    background-color: #F2F2F2;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    border-right: 1px solid #D9D9D9;
                    border-bottom: 1px solid #D9D9D9;
                    box-sizing: border-box;

                    img {
                        height: 1.08rem;
                    }

                    b {
                        font-size: .28rem;
                        color: #5F646E;
                    }

                    span {
                        font-size: .3rem;
                        display: block;
                        color: #41A441;

                        &.number {
                            color: #5F646E;
                        }
                    }               
                }
            }
        }            
    }
}
</style>